import {EventEmitter} from '@angular/core';
export class CurrencyService {
  currencyChange: EventEmitter<string> = new EventEmitter();
  constructor() {}
  emitNavChangeEvent(value) {
    this.currencyChange.emit(value);
  }
  getNavChangeEmitter() {
    return this.currencyChange;
  }
}
