import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Injectable } from '@angular/core';
import {Inject} from '@angular/core';


@Injectable()
export class CryptoService implements OnInit {

    constructor( protected http: HttpClient) { }

  ngOnInit(): void {
  }

  getMarkets() {
    return this.http.get('https://api.litebit.eu/markets');
  }

  getMarketsMoney() {
    return this.http.get('https://api.currencyfreaks.com/latest?apikey=1f5898aa0ae24e429703dd21120dc0bf&symbols=JPY,GBP,EUR,USD');
  }

  getMarket(name: string, year: number) {
    return this.http.get('http://longlived.nl' + '/api/GetMartket/' + name + '/inYear/' + year);
  }

  getMarketAll( year: number) {
    return this.http.get('http://longlived.nl' + '/api/GetMartketAll' + '/inYear/' + year);
  }
}
